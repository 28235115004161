import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

function Client({ name, url, site }) {
  return (
    <div className="bg-yellow text-dark-grey p-8 lg:p-16 border-b border-white">
      <div className="max-w-4xl mx-auto">
        <p className="text-3xl font-display font-bold">{name}</p>
        <OutboundLink href={url} className="hover:underline">
          {site}
        </OutboundLink>
      </div>
    </div>
  );
}

export default Client;
