import React from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactLink from "../components/contactLink";
import Client from "../components/client";

function ClientsPage() {
  return (
    <Layout>
      <div className="bg-grey-dark px-8 py-20">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-4xl text-white mb-4">
            Selected Clients
          </h3>
          <p className="text-xl text-white opacity-75">
            AKA the esteemed members of the IRL family.
          </p>
        </div>
      </div>

      <div className="bg-yellow">
        <div className="max-w-4xl mx-auto">

          <Client
            name="AmazingPhil"
            url="https://www.amazingphilshop.com/"
            site="amazingphilshop.com"
          />

          <Client
            name="Colin Furze"
            url="https://www.colinfurzeshop.com/"
            site="colinfurzeshop.com"
          />

          <Client
            name="Daniel Howell"
            url="https://shop.danielhowell.com/"
            site="shop.danielhowell.com"
          />

        </div>
      </div>

      <div className="bg-grey-dark p-16 text-center">
        <p className="max-w-4xl mx-auto text-xl text-white opacity-75 pb-8">
          Want to find out more about how we've helped our clients?
        </p>
        <Link
          to="/projects"
          className="text-xl bg-white text-grey-dark mt-4 px-6 py-4 inline-block shadow hover:shadow-lg"
        >
          See Projects &rarr;
        </Link>
      </div>

      <ContactLink />
    </Layout>
  );
}

export default ClientsPage;

export function Head() {
  return (
    <SEO title="Clients" />
  )
}
