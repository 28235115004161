import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import TypeFormLink from "./typeformLink";

function ContactLink() {
  return (
    <div className="bg-yellow text-grey-dark text-center px-8 py-24">
      <div className="max-w-4xl mx-auto">
        <h3 className="text-5xl font-display font-bold uppercase">We Want You!</h3>
        <p className="max-w-2xl mx-auto my-8">
          We’re always looking for more creators to join the IRL family. If
          you’re ready to make great merch, give your fans the best experience
          and to start conquering the world, it’s time to...
        </p>
        <OutboundLink
          href={TypeFormLink}
          className="text-xl bg-grey-dark text-yellow mt-4 px-6 py-4 inline-block shadow hover:shadow-lg"
        >
          Level up your merch &rarr;
        </OutboundLink>

        <p className="max-w-2xl mx-auto my-4">
          or{" "}
          <OutboundLink
            href="https://www.youtube.com/watch?v=ZBzitn5_OUg"
            target="_blank"
            className="underline"
          >
            get distracted by this
          </OutboundLink>
          .
        </p>
      </div>
    </div>
  );
}

export default ContactLink;
